<!--
 * @Description: 专题报名详情
 * @Date: 2020-05-22 13:13:46
 * @LastEditTime: 2020-08-24 14:35:29
 * @LastEditors: 胡椒
-->
<template>
  <div id="topicRegDetail">
    <div class="detail-info" v-if="projects">
      <span>报名截止：{{getDeadlineTime}}</span>
      <span>报名人数：{{getApplyDate}}</span>
    </div>
    <a-table
      v-auth="'operate_manage_projects_apply_page'"
      :rowKey="record => record.id"
      :columns="Columns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无报名申请' }"
      :scroll="{ x: scrollWidth }"
      @change="handleTableChange"
    >
      <template slot="channel" slot-scope="record">
        <!-- <a-tag>{{CHANNEL[record.user__channel]}}</a-tag> -->
        <a-tag color="purple" v-if="record.user__channel == 1">抖音</a-tag>
        <a-tag color="orange" v-if="record.user__channel == 2">淘宝</a-tag>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button size="small" class="mr-10" @click="handleShowUserDetail(record.user_id)">查看</a-button>
      </template>
    </a-table>
    <a-drawer
      title="用户详情"
      placement="right"
      width="850"
      @close="handleUserInfoDrawerClose"
      :visible="userDetailDrawerVisiable"
    >
      <UserDtail ref="userDetail" :id="userDetailDrawerId" />
    </a-drawer>
  </div>
</template>

<script>
import { getTopicApply } from '@/service/topic'
import { CHANNEL } from '@/const/index'
import UserDtail from '@/components/admin/user-detail'
// 详情默认表头
const startColumns = [
  {
    title: "注册手机",
    dataIndex: "user__mobile",
    align: "left",
    width: 160,
    fixed: "left"
  },
  {
    title: "渠道",
    // dataIndex: "user__channel",
    align: "center",
    width: 60,
    scopedSlots: { customRender: 'channel' },
    fixed: "left"
  },
  {
    title: "抖音昵称",
    dataIndex: "user__douyin_nickname",
    align: "center",
    width: 160,
  }
];
const endColumns = [
  {
    title: "报名时间",
    dataIndex: "create_time",
    align: "center",
    width: 190,
    // fixed: "right"
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" },
    fixed: "right"
  }
];
export default {
  name: "TopicRegDetail",
  components: { UserDtail },
  data () {
    return {
      CHANNEL,
      startColumns, // 前面的固定表头
      endColumns, // 后面的固定表头
      Columns: [],
      list: [],
      listLoading: false,
      projects: null, // 专题信息
      allDetailInfo: null, // 接口返回的全部数据
      scrollWidth: 0,
      userDetailDrawerVisiable: false, // 用户详情抽屉显示标志
      userDetailDrawerId: null,
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    }
  },
  computed: {
    // 计算截止时间
    getDeadlineTime () {
      return this.projects && this.projects.apply_deadline_time ? this.projects.apply_deadline_time.substr(0, 16) : ''
    },
    // 计算报名人数
    getApplyDate () {
      return this.projects ? this.projects.apply_num : ''
    }
  },
  mounted () {
    this.getTopicApplyDetail()
  },
  methods: {
    // 获取报名详情
    async getTopicApplyDetail () {
      if (!this.$route.params.id) return this.$message.warning('活动ID有误，请检查')
      const data = { projects_id: this.$route.params.id }
      const { err, res } = await getTopicApply(data)
      if (!err && res.success) {
        this.allDetailInfo = res.data
        // 赋值专题信息
        this.projects = res.data.projects
        this.scrollWidth = res.data.projects.apply_fields.length * 230
        // 处理表头
        const customColumns = res.data.projects.apply_fields
          ? res.data.projects.apply_fields.map(field => {
            return {
              title: field.desc.replace('：', ''),
              dataIndex: field.name,
              align: "center",
              width: 150,
            }
          })
          : [];
        this.Columns = this.startColumns.concat(customColumns).concat(this.endColumns)
        // 处理数据
        this.list = res.data.results.map(item => Object.assign(item, item.apply_fields))
      }
    },
    // 点击抽屉关闭回调
    handleUserInfoDrawerClose () {
      this.userDetailDrawerVisiable = false
    },
    // 点击查看用户详情
    handleShowUserDetail (id) {
      this.userDetailDrawerId = id
      this.userDetailDrawerVisiable = true
      if (this.$refs.userDetail) this.$refs.userDetail.init(id)
    },
    handleTableChange(pagination) {
      if (pagination) {
        this.pagination.current = pagination.current;
      }
      this.getTopicApplyDetail();
    },
  }
}
</script>

<style lang="less" scope>
  #topicRegDetail {
    .detail-info {
      margin-bottom: 10px;
      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
</style>
